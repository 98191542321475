import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <div className='Footer py-2'>
    </div>
  )
}

export default Footer;
